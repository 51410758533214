
    <template>
      <section class="content element-doc">
        <h2>Button 按钮</h2>
<p>常用的操作按钮。</p>
<h3>基础用法</h3>
<p>基础的按钮用法。</p>
<demo-block>
        <div><p>使用<code>type</code>、<code>plain</code>、<code>round</code>和<code>circle</code>属性来定义 Button 的样式。</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-row&gt;
    &lt;el-button&gt;默认按钮&lt;/el-button&gt;
    &lt;el-button type=&quot;primary&quot;&gt;主要按钮&lt;/el-button&gt;
    &lt;el-button type=&quot;success&quot;&gt;成功按钮&lt;/el-button&gt;
    &lt;el-button type=&quot;info&quot;&gt;信息按钮&lt;/el-button&gt;
    &lt;el-button type=&quot;warning&quot;&gt;警告按钮&lt;/el-button&gt;
    &lt;el-button type=&quot;danger&quot;&gt;危险按钮&lt;/el-button&gt;
  &lt;/el-row&gt;

  &lt;el-row&gt;
    &lt;el-button plain&gt;朴素按钮&lt;/el-button&gt;
    &lt;el-button type=&quot;primary&quot; plain&gt;主要按钮&lt;/el-button&gt;
    &lt;el-button type=&quot;success&quot; plain&gt;成功按钮&lt;/el-button&gt;
    &lt;el-button type=&quot;info&quot; plain&gt;信息按钮&lt;/el-button&gt;
    &lt;el-button type=&quot;warning&quot; plain&gt;警告按钮&lt;/el-button&gt;
    &lt;el-button type=&quot;danger&quot; plain&gt;危险按钮&lt;/el-button&gt;
  &lt;/el-row&gt;

  &lt;el-row&gt;
    &lt;el-button round&gt;圆角按钮&lt;/el-button&gt;
    &lt;el-button type=&quot;primary&quot; round&gt;主要按钮&lt;/el-button&gt;
    &lt;el-button type=&quot;success&quot; round&gt;成功按钮&lt;/el-button&gt;
    &lt;el-button type=&quot;info&quot; round&gt;信息按钮&lt;/el-button&gt;
    &lt;el-button type=&quot;warning&quot; round&gt;警告按钮&lt;/el-button&gt;
    &lt;el-button type=&quot;danger&quot; round&gt;危险按钮&lt;/el-button&gt;
  &lt;/el-row&gt;

  &lt;el-row&gt;
    &lt;el-button icon=&quot;el-icon-search&quot; circle&gt;&lt;/el-button&gt;
    &lt;el-button type=&quot;primary&quot; icon=&quot;el-icon-edit&quot; circle&gt;&lt;/el-button&gt;
    &lt;el-button type=&quot;success&quot; icon=&quot;el-icon-check&quot; circle&gt;&lt;/el-button&gt;
    &lt;el-button type=&quot;info&quot; icon=&quot;el-icon-message&quot; circle&gt;&lt;/el-button&gt;
    &lt;el-button type=&quot;warning&quot; icon=&quot;el-icon-star-off&quot; circle&gt;&lt;/el-button&gt;
    &lt;el-button type=&quot;danger&quot; icon=&quot;el-icon-delete&quot; circle&gt;&lt;/el-button&gt;
  &lt;/el-row&gt;
&lt;/template&gt;
</code></pre></template></demo-block><h3>禁用状态</h3>
<p>按钮不可用状态。</p>
<demo-block>
        <div><p>你可以使用<code>disabled</code>属性来定义按钮是否可用，它接受一个<code>Boolean</code>值。</p>
</div>
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-row&gt;
  &lt;el-button disabled&gt;默认按钮&lt;/el-button&gt;
  &lt;el-button type=&quot;primary&quot; disabled&gt;主要按钮&lt;/el-button&gt;
  &lt;el-button type=&quot;success&quot; disabled&gt;成功按钮&lt;/el-button&gt;
  &lt;el-button type=&quot;info&quot; disabled&gt;信息按钮&lt;/el-button&gt;
  &lt;el-button type=&quot;warning&quot; disabled&gt;警告按钮&lt;/el-button&gt;
  &lt;el-button type=&quot;danger&quot; disabled&gt;危险按钮&lt;/el-button&gt;
&lt;/el-row&gt;

&lt;el-row&gt;
  &lt;el-button plain disabled&gt;朴素按钮&lt;/el-button&gt;
  &lt;el-button type=&quot;primary&quot; plain disabled&gt;主要按钮&lt;/el-button&gt;
  &lt;el-button type=&quot;success&quot; plain disabled&gt;成功按钮&lt;/el-button&gt;
  &lt;el-button type=&quot;info&quot; plain disabled&gt;信息按钮&lt;/el-button&gt;
  &lt;el-button type=&quot;warning&quot; plain disabled&gt;警告按钮&lt;/el-button&gt;
  &lt;el-button type=&quot;danger&quot; plain disabled&gt;危险按钮&lt;/el-button&gt;
&lt;/el-row&gt;
</code></pre></template></demo-block><h3>文字按钮</h3>
<p>没有边框和背景色的按钮。</p>
<demo-block>
        
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-button type=&quot;text&quot;&gt;文字按钮&lt;/el-button&gt;
&lt;el-button type=&quot;text&quot; disabled&gt;文字按钮&lt;/el-button&gt;
</code></pre></template></demo-block><h3>图标按钮</h3>
<p>带图标的按钮可增强辨识度（有文字）或节省空间（无文字）。</p>
<demo-block>
        <div><p>设置<code>icon</code>属性即可，icon 的列表可以参考 Element3 的 icon 组件，也可以设置在文字右边的 icon ，只要使用<code>i</code>标签即可，可以使用自定义图标。</p>
</div>
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-button type=&quot;primary&quot; icon=&quot;el-icon-edit&quot;&gt;&lt;/el-button&gt;
&lt;el-button type=&quot;primary&quot; icon=&quot;el-icon-share&quot;&gt;&lt;/el-button&gt;
&lt;el-button type=&quot;primary&quot; icon=&quot;el-icon-delete&quot;&gt;&lt;/el-button&gt;
&lt;el-button type=&quot;primary&quot; icon=&quot;el-icon-search&quot;&gt;搜索&lt;/el-button&gt;
&lt;el-button type=&quot;primary&quot;
  &gt;上传&lt;i class=&quot;el-icon-upload el-icon--right&quot;&gt;&lt;/i
&gt;&lt;/el-button&gt;
</code></pre></template></demo-block><h3>按钮组</h3>
<p>以按钮组的方式出现，常用于多项类似操作。</p>
<demo-block>
        <div><p>使用<code>&lt;el-button-group&gt;</code>标签来嵌套你的按钮。</p>
</div>
        <template #source><element-demo4 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-button-group&gt;
  &lt;el-button type=&quot;primary&quot; icon=&quot;el-icon-arrow-left&quot;&gt;上一页&lt;/el-button&gt;
  &lt;el-button type=&quot;primary&quot;
    &gt;下一页&lt;i class=&quot;el-icon-arrow-right el-icon--right&quot;&gt;&lt;/i
  &gt;&lt;/el-button&gt;
&lt;/el-button-group&gt;
&lt;el-button-group&gt;
  &lt;el-button type=&quot;primary&quot; icon=&quot;el-icon-edit&quot;&gt;&lt;/el-button&gt;
  &lt;el-button type=&quot;primary&quot; icon=&quot;el-icon-share&quot;&gt;&lt;/el-button&gt;
  &lt;el-button type=&quot;primary&quot; icon=&quot;el-icon-delete&quot;&gt;&lt;/el-button&gt;
&lt;/el-button-group&gt;
</code></pre></template></demo-block><h3>加载中</h3>
<p>点击按钮后进行数据加载操作，在按钮上显示加载状态。</p>
<demo-block>
        <div><p>要设置为 loading 状态，只要设置<code>loading</code>属性为<code>true</code>即可。</p>
</div>
        <template #source><element-demo5 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-button type=&quot;primary&quot; :loading=&quot;true&quot;&gt;加载中&lt;/el-button&gt;
</code></pre></template></demo-block><h3>不同尺寸</h3>
<p>Button 组件提供除了默认值以外的三种尺寸，可以在不同场景下选择合适的按钮尺寸。</p>
<demo-block>
        <div><p>额外的尺寸：<code>medium</code>、<code>small</code>、<code>mini</code>，通过设置<code>size</code>属性来配置它们。</p>
</div>
        <template #source><element-demo6 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-row&gt;
  &lt;el-button&gt;默认按钮&lt;/el-button&gt;
  &lt;el-button size=&quot;medium&quot;&gt;中等按钮&lt;/el-button&gt;
  &lt;el-button size=&quot;small&quot;&gt;小型按钮&lt;/el-button&gt;
  &lt;el-button size=&quot;mini&quot;&gt;超小按钮&lt;/el-button&gt;
&lt;/el-row&gt;
&lt;el-row&gt;
  &lt;el-button round&gt;默认按钮&lt;/el-button&gt;
  &lt;el-button size=&quot;medium&quot; round&gt;中等按钮&lt;/el-button&gt;
  &lt;el-button size=&quot;small&quot; round&gt;小型按钮&lt;/el-button&gt;
  &lt;el-button size=&quot;mini&quot; round&gt;超小按钮&lt;/el-button&gt;
&lt;/el-row&gt;
</code></pre></template></demo-block><h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>size</td>
<td>尺寸</td>
<td>string</td>
<td>medium / small / mini</td>
<td>—</td>
</tr>
<tr>
<td>type</td>
<td>类型</td>
<td>string</td>
<td>primary / success / warning / danger / info / text</td>
<td>—</td>
</tr>
<tr>
<td>plain</td>
<td>是否朴素按钮</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>round</td>
<td>是否圆角按钮</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>circle</td>
<td>是否圆形按钮</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>loading</td>
<td>是否加载中状态</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>disabled</td>
<td>是否禁用状态</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>icon</td>
<td>图标类名</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>autofocus</td>
<td>是否默认聚焦</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>native-type</td>
<td>原生 type 属性</td>
<td>string</td>
<td>button / submit / reset</td>
<td>button</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, null, {
          default: _withCtx(() => [
            _createTextVNode("默认按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, { type: "primary" }, {
          default: _withCtx(() => [
            _createTextVNode("主要按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, { type: "success" }, {
          default: _withCtx(() => [
            _createTextVNode("成功按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, { type: "info" }, {
          default: _withCtx(() => [
            _createTextVNode("信息按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, { type: "warning" }, {
          default: _withCtx(() => [
            _createTextVNode("警告按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, { type: "danger" }, {
          default: _withCtx(() => [
            _createTextVNode("危险按钮")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, { plain: "" }, {
          default: _withCtx(() => [
            _createTextVNode("朴素按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          plain: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("主要按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "success",
          plain: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("成功按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "info",
          plain: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("信息按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "warning",
          plain: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("警告按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "danger",
          plain: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("危险按钮")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, { round: "" }, {
          default: _withCtx(() => [
            _createTextVNode("圆角按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          round: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("主要按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "success",
          round: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("成功按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "info",
          round: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("信息按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "warning",
          round: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("警告按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "danger",
          round: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("危险按钮")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          icon: "el-icon-search",
          circle: ""
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          icon: "el-icon-edit",
          circle: ""
        }),
        _createVNode(_component_el_button, {
          type: "success",
          icon: "el-icon-check",
          circle: ""
        }),
        _createVNode(_component_el_button, {
          type: "info",
          icon: "el-icon-message",
          circle: ""
        }),
        _createVNode(_component_el_button, {
          type: "warning",
          icon: "el-icon-star-off",
          circle: ""
        }),
        _createVNode(_component_el_button, {
          type: "danger",
          icon: "el-icon-delete",
          circle: ""
        })
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, { disabled: "" }, {
          default: _withCtx(() => [
            _createTextVNode("默认按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          disabled: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("主要按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "success",
          disabled: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("成功按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "info",
          disabled: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("信息按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "warning",
          disabled: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("警告按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "danger",
          disabled: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("危险按钮")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          plain: "",
          disabled: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("朴素按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          plain: "",
          disabled: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("主要按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "success",
          plain: "",
          disabled: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("成功按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "info",
          plain: "",
          disabled: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("信息按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "warning",
          plain: "",
          disabled: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("警告按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "danger",
          plain: "",
          disabled: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("危险按钮")
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_button, { type: "text" }, {
      default: _withCtx(() => [
        _createTextVNode("文字按钮")
      ]),
      _: 1
    }),
    _createVNode(_component_el_button, {
      type: "text",
      disabled: ""
    }, {
      default: _withCtx(() => [
        _createTextVNode("文字按钮")
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, createTextVNode: _createTextVNode, withCtx: _withCtx, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_button, {
      type: "primary",
      icon: "el-icon-edit"
    }),
    _createVNode(_component_el_button, {
      type: "primary",
      icon: "el-icon-share"
    }),
    _createVNode(_component_el_button, {
      type: "primary",
      icon: "el-icon-delete"
    }),
    _createVNode(_component_el_button, {
      type: "primary",
      icon: "el-icon-search"
    }, {
      default: _withCtx(() => [
        _createTextVNode("搜索")
      ]),
      _: 1
    }),
    _createVNode(_component_el_button, { type: "primary" }, {
      default: _withCtx(() => [
        _createTextVNode("上传"),
        _createVNode("i", { class: "el-icon-upload el-icon--right" })
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo4": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_button_group = _resolveComponent("el-button-group")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_button_group, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          type: "primary",
          icon: "el-icon-arrow-left"
        }, {
          default: _withCtx(() => [
            _createTextVNode("上一页")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, { type: "primary" }, {
          default: _withCtx(() => [
            _createTextVNode("下一页"),
            _createVNode("i", { class: "el-icon-arrow-right el-icon--right" })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_button_group, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          type: "primary",
          icon: "el-icon-edit"
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          icon: "el-icon-share"
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          icon: "el-icon-delete"
        })
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo5": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createBlock(_component_el_button, {
    type: "primary",
    loading: true
  }, {
    default: _withCtx(() => [
      _createTextVNode("加载中")
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo6": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, null, {
          default: _withCtx(() => [
            _createTextVNode("默认按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, { size: "medium" }, {
          default: _withCtx(() => [
            _createTextVNode("中等按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, { size: "small" }, {
          default: _withCtx(() => [
            _createTextVNode("小型按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, { size: "mini" }, {
          default: _withCtx(() => [
            _createTextVNode("超小按钮")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, { round: "" }, {
          default: _withCtx(() => [
            _createTextVNode("默认按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          size: "medium",
          round: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("中等按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          size: "small",
          round: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("小型按钮")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          size: "mini",
          round: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("超小按钮")
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  